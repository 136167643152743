import React, { FC, memo } from "react";
import { IconType } from "enums/Icon";
import { useTranslation } from "react-i18next";
import formatter from "../../../../utils/formatter";
import {
  ServiceRequestIcon,
  ServiceRequestItem,
  ServiceRequestText,
} from "./ServiceRequestsList.style";

interface ServiceRequestsListProps {
  serviceRequests: any;
}

const ServiceRequestsList: FC<ServiceRequestsListProps> = ({
  serviceRequests,
}) => {
  const { t } = useTranslation("common");

  return serviceRequests.map((request: any) => {
    const cost = request.getIn(["service_request_type", "cost"]);
    // TODO: FIXME:
    // const requestType =
    //   requestNames[
    //     request
    //       .getIn(["service_request_type", "type"])
    //       .toLowerCase() as keyof typeof requestNames
    //   ];
    return null;

    // return (
    //   <ServiceRequestItem key={request.get("id")} $isSelected>
    //     <ServiceRequestIcon type={requestType as IconType} />
    //     <ServiceRequestText>
    //       {t(`serviceRequests.${requestType}.title`)}
    //       <span>${formatter.currency(cost)}</span>
    //     </ServiceRequestText>
    //   </ServiceRequestItem>
    // );
  });
};

export default memo(ServiceRequestsList);
