import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import Input from "components/Input/Input";
import { FormikProps } from "formik";
import { List } from "immutable";
import { useTranslation } from "react-i18next";
import { FormValues } from "types/customDeclaration";
import { detectCyrillic } from "../../helpers";
import {
  Box,
  CustomButton,
  InnerBox,
  NoHsCodeError,
} from "./CustomSelectDescription.styles";

type InitialOptions =
  | { value: string; value_ru: string; hsCodeNotFound?: undefined }
  | { hsCodeNotFound: true };

interface CustomSelectDescriptionProps {
  name: string;
  value: string;
  placeholder?: string;
  initialOptions: List<InitialOptions>;
  handleChange: (selectedOption: string) => void;
  handleBlur: FormikProps<FormValues>["handleBlur"];
}

const CustomSelectDescription: FC<CustomSelectDescriptionProps> = ({
  value,
  handleChange,
  handleBlur,
  initialOptions,
  name,
  placeholder = "",
}) => {
  const { t } = useTranslation("common");
  const [inputValue, setInputValue] = useState(value);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isTextCyrillic, setIsCyrillic] = useState(false);
  const [options, setOptions] = useState<InitialOptions[]>([]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const description = event.target.value;
      const descriptionLength = description.length;
      setInputValue(description);

      const isTextCyrillic = detectCyrillic(description);
      setIsCyrillic(isTextCyrillic);

      if (descriptionLength >= 2) {
        const filteredOptions = initialOptions.toArray().filter((option) => {
          if (!option.hsCodeNotFound) {
            return (isTextCyrillic ? option.value_ru : option.value)
              .toLowerCase()
              .includes(
                description.toLowerCase().substring(0, descriptionLength),
              );
          } else {
            return option;
          }
        });
        const nextOptions = filteredOptions.length
          ? filteredOptions
          : [{ hsCodeNotFound: true as true }];
        setOptions(nextOptions);
        setIsOpenMenu(true);

        handleChange(description);
      } else {
        setIsOpenMenu(false);
        handleChange(description);
      }
    },
    [initialOptions],
  );

  const handleClickButton = (newValue: string) => {
    setInputValue(newValue);
    handleChange(newValue);
    setIsOpenMenu(false);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setIsOpenMenu(false);
    }, 300);
    handleBlur(event);
  };

  return (
    <>
      <Input
        name={name}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        placeholder={placeholder}
      />
      {isOpenMenu && (
        <Box>
          <InnerBox>
            {options.map((el, index) => {
              if (el.hsCodeNotFound) {
                if (isTextCyrillic) {
                  return (
                    <NoHsCodeError>{t("shipping.noHsCodeFound")}</NoHsCodeError>
                  );
                }
                return null;
              }

              const value = isTextCyrillic ? el.value_ru : el.value;

              return (
                <CustomButton
                  key={value + index}
                  onClick={() => {
                    handleClickButton(el.value);
                  }}
                >
                  {value}
                </CustomButton>
              );
            })}
          </InnerBox>
        </Box>
      )}
    </>
  );
};

export default CustomSelectDescription;
