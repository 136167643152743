import React, { FC } from "react";
import packageIcon from "components/Icon/icons/package.svg";
import { IconType } from "enums/Icon";
import { List } from "immutable";
import { useTranslation } from "react-i18next";
import {
  getAddonDescription,
  getAddonPrice,
  getAddonsIconsImage,
} from "../../../deliveryHelper";
import {
  Description,
  HeadText,
  HeadTextCompact,
  Item,
  ItemCompact,
  ItemHead,
  ItemHeadContent,
  ItemHeadPrice,
  ItemIcon,
  ItemLogo,
  ItemSection,
  ListItem,
  ListItemCompact,
  ServiceRequestIcon,
} from "./SideAddonItem.styles";

interface SideAddonItemProps {
  itemAddons: any[];
  allItemsAddons?: any[];
  addons: any;
  cartItem: any;
  consolidationAddons?: any;
  showAsCompact?: boolean;
}

const SideAddonItem: FC<SideAddonItemProps> = ({
  itemAddons,
  allItemsAddons,
  addons,
  consolidationAddons,
  cartItem,
  showAsCompact,
}) => {
  const { t } = useTranslation("common");
  const uniqueAddons = allItemsAddons
    ? itemAddons.filter((x) => !allItemsAddons.includes(x))
    : itemAddons;

  const hasAddons = !!(itemAddons && itemAddons.length && uniqueAddons.length);
  const allAddons =
    addons.size && consolidationAddons.size
      ? List(addons).merge(consolidationAddons)
      : List();
  const logoUrl = cartItem.get("emblem_thumb_url") || packageIcon;
  const heading = cartItem.get("shipment_tracking_number");
  const description = cartItem.get("customer_comment");
  const serviceRequests = cartItem.get("service_requests", List());

  if (!hasAddons) {
    return null;
  }

  if (showAsCompact) {
    return (
      <ItemCompact>
        <ItemLogo image={logoUrl} size={20} />
        <HeadTextCompact>{heading}</HeadTextCompact>
        {serviceRequests.map((request: any) => {
          // TODO: FIXME:
          const requestType = request
            .getIn(["service_request_type", "type"])
            .toLowerCase();

          return (
            <ListItemCompact key={request.get("id")}>
              <ServiceRequestIcon type={requestType as IconType} />
            </ListItemCompact>
          );
        })}
        {uniqueAddons.map((id) => (
          <ListItemCompact key={id}>
            <ItemIcon>{getAddonsIconsImage(id, allAddons)}</ItemIcon>
          </ListItemCompact>
        ))}
      </ItemCompact>
    );
  }

  let price = 0;
  uniqueAddons.forEach(
    (id) => (price += parseFloat(getAddonPrice(id, addons))),
  );
  serviceRequests.forEach(
    (request: any) =>
      (price += parseFloat(request.getIn(["service_request_type", "cost"]))),
  );

  return (
    <Item>
      <ItemHead withDescription={!!description}>
        <ItemLogo image={logoUrl} size={20} />
        <ItemHeadContent>
          <HeadText>
            {heading}:{description && <Description>{description}</Description>}
          </HeadText>
          <ItemHeadPrice>
            {price !== 0 ? `$${price.toFixed(2)}` : "Free"}
          </ItemHeadPrice>
        </ItemHeadContent>
      </ItemHead>
      <ItemSection>
        {serviceRequests.map((request: any) => {
          // TODO: FIXME:
          const requestType = request
            .getIn(["service_request_type", "type"])
            .toLowerCase();

          return (
            <ListItem key={request.get("id")}>
              <ServiceRequestIcon type={requestType as IconType} />
              <span>{t(`serviceRequests.${requestType}.title`)}</span>
            </ListItem>
          );
        })}

        {uniqueAddons.map((id) => (
          <ListItem key={id}>
            <ItemIcon>{getAddonsIconsImage(id, allAddons)}</ItemIcon>
            {getAddonDescription(id, allAddons)}
          </ListItem>
        ))}
      </ItemSection>
    </Item>
  );
};

export default React.memo(SideAddonItem);
