import React from "react";
import pathOr from "@ramda/pathor";
import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { List, Map } from "immutable";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  ServiceRequestFormHelpers,
  ServiceRequestFormValues,
  ServiceRequestProps,
} from "types/StorageForms/ServiceRequest";
import * as yup from "yup";
import {
  closeRightPanel,
  openRightPanel,
} from "../../../../reduxFolder/reducers/rightPanel";
import {
  cancelRequestInventoryServiceRequest,
  cancelServiceRequest,
  completeRequestTransferInventoryItems,
  completeRequestTransferShipments,
  createRequestInventoryShipment,
  createRequestTransferInventoryItems,
  createRequestTransferShipments,
  createServiceRequest,
} from "../../../../reduxFolder/reducers/serviceRequests";
import {
  addServiceRequest,
  getShipment,
  getStorage,
} from "../../../../reduxFolder/reducers/storage";
import {
  requestsSelectServiceTypes,
  requestsSelectTransferRequests,
} from "../../../../reduxFolder/selectors/serviceRequestsSelectors";
import {
  storageSelectDetailedLoading,
  storageSelectItems,
  storageSelectSelected,
} from "../../../../reduxFolder/selectors/storageSelectors";
import { isTransferringState } from "../../../../utils/common";
import { toastResponseError } from "../../../../utils/responseMessageHelper";

const cancelableStates = [
  "translated",
  "pending_translation",
  "pending_moderation",
  "warehouse_requested",
  "pending_requestInventoryShipment",
];

const isUnprocessedRequest = (serviceRequests = []) =>
  serviceRequests.find((name) => name === "unprocessed");

const getRequestInventoryInfo = (unpackingTask: any) => {
  const isRequestActive = unpackingTask.get("state") === "requested";

  return Map({
    hasRequest: isRequestActive,
    isSubmitHidden: false,
    isRequestCancelable: isRequestActive,
    processed: false,
    service_request_type: Map({
      type: "requestInventoryShipment",
    }),
    state: "pending_requestInventoryShipment",
  });
};

export const getActiveShipmentRequests = (
  serviceRequestsTypes: any,
  activeShipmentRequests: any,
) => {
  if (!activeShipmentRequests) return List();

  const retVal = activeShipmentRequests.map((x: any) => {
    return x.merge({
      type: x.getIn(["service_request_type", "type"]),
    });
  });

  return retVal;
};

export const getActiveRequestForType = (
  serviceRequestsTypes: any,
  activeShipmentRequests: any,
  requestType: string,
) => {
  const list = getActiveShipmentRequests(
    serviceRequestsTypes,
    activeShipmentRequests,
  );
  const activeRequest = list.find((x: any) => {
    if (
      x.get("service_request_type") &&
      x.get("service_request_type").get("group")
    ) {
      return (
        x.get("service_request_type").get("group").toLowerCase() === requestType
      );
    } else if (
      x.get("service_request_type") &&
      x.get("service_request_type").get("collection")
    ) {
      return (
        x.get("service_request_type").get("collection").toLowerCase() ===
        requestType
      );
    } else {
      return x.get("type") === requestType;
    }
  });

  if (!activeRequest) {
    return Map({
      hasRequest: false,
    });
  }

  const isRequestCancelable = cancelableStates.includes(
    activeRequest.get("state") as string,
  );
  const processed = activeRequest.get("processed");

  const isSubmitHidden = !isRequestCancelable || processed;

  return activeRequest.merge({
    hasRequest: true,
    isSubmitHidden,
    isRequestCancelable,
    processed,
  });
};

export const getRequestInfoForType = (
  serviceRequestsTypes: any,
  requestType: string | undefined,
) => {
  if (!requestType) return null;
  for (const serviceRequest of serviceRequestsTypes) {
    if (!serviceRequest.get("complexType")) {
      if (serviceRequest.get("type") === requestType) return serviceRequest;
    } else {
      if (
        serviceRequest.get("type") === requestType ||
        serviceRequest.get("requestKeys").get(requestType)
      )
        return serviceRequest;
    }
  }
};

const handleRequestSubmit = (
  values: ServiceRequestFormValues,
  {
    setSubmitting,
    props: {
      panelData,
      openRightPanel,
      addServiceRequest,
      createServiceRequest,
      cancelServiceRequest,
      cancelRequestInventoryServiceRequest,
      createRequestInventoryShipment,
      createRequestTransferShipments,
      createRequestTransferInventoryItems,
      completeRequestTransferShipments,
      completeRequestTransferInventoryItems,
      getShipment,
      getStorage,
      closeRightPanel,
      serviceRequestsTypes = List(),
      selectedParcels,
    },
  }: ServiceRequestFormHelpers,
) => {
  const requestType = panelData.get("requestType");

  if (requestType === "requestTransferShipment") {
    const id = panelData.get("id");
    const partnerTransferId = values.partnerTransferId ?? "";
    const isInventoryItemVariant = panelData.get("isInventoryItemVariant");
    const state = panelData.get("state");
    const isActiveTransferRequest = isTransferringState(state);

    if (isActiveTransferRequest) {
      if (isInventoryItemVariant) {
        return completeRequestTransferInventoryItems({
          codes: [partnerTransferId],
        })
          .then(() => {
            getStorage();
            closeRightPanel();
            setSubmitting(false);
          })
          .catch((error: Error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
      } else {
        return completeRequestTransferShipments({
          codes: [partnerTransferId],
        })
          .then(() => {
            getStorage();
            closeRightPanel();
            setSubmitting(false);
          })
          .catch((error: Error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
      }
    } else {
      if (isInventoryItemVariant) {
        const transferInventoryItemId = panelData.get(
          "transferInventoryItemId",
        );
        const selectedInventoryItems = panelData.get("selectedInventoryItems");
        const shipment = panelData.get("shipment");

        const filteredItems =
          selectedInventoryItems && selectedInventoryItems.length
            ? shipment
                .get("items")
                .filter((x: any) =>
                  selectedInventoryItems.includes(x.get("id")),
                )
            : shipment
                .get("items")
                .filter((x: any) => transferInventoryItemId === x.get("id"));

        const ids = filteredItems.map((x: any) => x.get("id"));
        const skuList = filteredItems.map((x: any) => x.get("sku"));

        return createRequestTransferInventoryItems({
          sku: skuList,
          ids,
          partnerTransferId,
        })
          .then(() => {
            setSubmitting(false);
          })
          .catch((error: Error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
      } else {
        const bulkTransferIds = selectedParcels?.toJS();
        const ids = bulkTransferIds?.length ? bulkTransferIds : [id];

        return createRequestTransferShipments({
          ids,
          partnerTransferId,
        })
          .then(() => {
            setSubmitting(false);
          })
          .catch((error: Error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
      }
    }
  } else if (requestType === "requestInventoryShipment") {
    const shipmentId = panelData.get("id");
    const id = panelData
      .get("requestInventoryShipmentData", Map())
      .get("items", List())
      .get(0, Map())
      .get("id");

    const unpackingServiceRequest = panelData
      .get("requestInventoryShipmentData", Map())
      .get("unpacking_task", Map());
    const status = unpackingServiceRequest.get("state");

    if (status === "requested") {
      cancelRequestInventoryServiceRequest({
        taskId: unpackingServiceRequest.get("id"),
      })
        .then(() => {
          getShipment(shipmentId).then(() => {
            openRightPanel("STORAGE_ITEM", panelData);
          });
        })
        .catch((error) => {
          toastResponseError(error);
          setSubmitting(false);
        });
    } else {
      createRequestInventoryShipment({ id })
        .then(() => {
          getShipment(shipmentId).then(() => {
            openRightPanel("STORAGE_ITEM", panelData);
          });
        })
        .catch((error: Error) => {
          toastResponseError(error);
          setSubmitting(false);
        });
    }
  } else {
    let requestTypeArray =
      values.selectedGroupOption ?? panelData.get("requestType");
    if (!Array.isArray(requestTypeArray)) {
      requestTypeArray = [requestTypeArray];
    }
    const promiseArray = [];

    for (const requestTypeArrayElement of requestTypeArray) {
      const dynamicRequestType = getRequestInfoForType(
        serviceRequestsTypes,
        requestTypeArrayElement,
      );
      const isDynamicComplexRequest = !!dynamicRequestType.get("complexType");
      const requestInfo = getActiveRequestForType(
        panelData.serviceRequestsTypes,
        panelData.get("requests"),
        requestTypeArrayElement,
      );
      const hasRequest = requestInfo.get("hasRequest");

      if (isDynamicComplexRequest) {
        if (hasRequest) {
          const data = {
            itemId: panelData.get("id"),
            requestId: requestInfo.get("id"),
          };

          const promReq = cancelServiceRequest(data)
            .then((response) => {
              const value = pathOr(Map(), ["value"], response);
              const data = value.get("data");

              if (data) {
                addServiceRequest(
                  Map({ packageId: panelData.get("id"), data }),
                );
              }
            })
            .catch((error) => {
              toastResponseError(error);
              setSubmitting(false);
            });
          promiseArray.push(promReq);
        } else {
          const data = {
            itemId: panelData.get("id"),
            service_request_type_id: dynamicRequestType
              .get("requestKeys")
              .get(requestTypeArrayElement)
              .get("id"),
            user_comment: values.comment,
          };

          const promReq = createServiceRequest(data)
            .then((response) => {
              const value = pathOr(Map(), ["value"], response);
              const data = value.get("data");

              if (data) {
                addServiceRequest(
                  Map({ packageId: panelData.get("id"), data }),
                );
              }
            })
            .catch((error) => {
              toastResponseError(error);
              setSubmitting(false);
            });
          promiseArray.push(promReq);
        }
      } else {
        const onSubmit = hasRequest
          ? cancelServiceRequest
          : createServiceRequest;

        const data = hasRequest
          ? { itemId: panelData.get("id"), requestId: requestInfo.get("id") }
          : {
              itemId: panelData.get("id"),
              service_request_type_id: dynamicRequestType.get("id"),
              user_comment: values.comment,
            };

        const promReq = onSubmit(data)
          .then((response) => {
            const value = pathOr(Map(), ["value"], response);
            const data = value.get("data");

            if (data) {
              addServiceRequest(Map({ packageId: panelData.get("id"), data }));
            }
          })
          .catch((error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
        promiseArray.push(promReq);
      }

      Promise.all(promiseArray).then(() => {
        openRightPanel("STORAGE_ITEM", panelData);
      });
    }
  }
};

const withConnect = connect(
  createStructuredSelector({
    isLoading: storageSelectDetailedLoading,
    transferRequests: requestsSelectTransferRequests,
    selectedParcels: storageSelectSelected,
    storageItems: storageSelectItems,
    serviceRequestsTypes: requestsSelectServiceTypes,
  }),
  {
    openRightPanel,
    closeRightPanel,
    addServiceRequest,
    createServiceRequest,
    cancelServiceRequest,
    cancelRequestInventoryServiceRequest,
    createRequestInventoryShipment,
    createRequestTransferShipments,
    createRequestTransferInventoryItems,
    completeRequestTransferShipments,
    completeRequestTransferInventoryItems,
    getShipment,
    getStorage,
  },
);

export {
  handleRequestSubmit,
  withConnect,
  getRequestInventoryInfo,
  cancelableStates,
  isUnprocessedRequest,
};

export const serviceRequestFormik = ({
  t,
  ...props
}: ServiceRequestProps & { t: TFunction }) => ({
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: {
    comment: "",
  },
  validationSchema: () => {
    const requestType = props.panelData.get("requestType");

    if (requestType === "requestInventoryShipment") {
      return yup.object({});
    } else if (requestType === "requestTransferShipment") {
      const isClientTransferring = isTransferringState(
        props.panelData.get("state"),
      );

      if (isClientTransferring) {
        return yup.object({
          partnerTransferId: yup
            .string()
            .required(
              t("error.required", { field: t("common.partnerTransferId") }),
            ),
        });
      }

      return yup.object({
        partnerTransferId: yup
          .number()
          .required(
            t("error.required", { field: t("common.partnerTransferId") }),
          ),
      });
    } else {
      const dynamicRequestType = getRequestInfoForType(
        props.serviceRequestsTypes,
        requestType,
      );

      if (!dynamicRequestType) return yup.object({});

      const isDynamicComplexRequest = !!dynamicRequestType.get("complexType");
      const requestInfo = getActiveRequestForType(
        props.panelData.serviceRequestsTypes,
        props.panelData.get("requests"),
        requestType,
      );

      if (requestInfo.get("hasRequest") || !isDynamicComplexRequest) {
        return yup.object({});
      }

      if (dynamicRequestType.get("complexType") === "group") {
        return yup.object({
          selectedGroupOption: yup
            .string()
            .required(t("error.required", { field: t("error.requiredField") })),
        });
      }

      return yup.object({
        selectedGroupOption: yup
          .array()
          .required(t("error.required", { field: t("error.requiredField") }))
          .min(1, t("error.required", { field: t("error.requiredField") })),
      });
    }
  },
  onSubmit: (
    values: ServiceRequestFormValues,
    formikBag: FormikHelpers<ServiceRequestFormValues>,
  ) => handleRequestSubmit(values, { props, ...formikBag }),
});
