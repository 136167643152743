import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  height: auto;
  min-height: 390px;
`;

interface SideBarWidthProps {
  $sideBarWidth: number;
}

export const ImageOverviewContainer = styled.div<SideBarWidthProps>`
  width: calc(100% - ${({ $sideBarWidth }) => $sideBarWidth}px);
`;

export const ImagesListContainer = styled.div<SideBarWidthProps>`
  width: ${({ $sideBarWidth }) => $sideBarWidth}px;
  position: relative;
`;

export const ImagesListContent = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  bottom: 0;
  overflow-y: auto;
`;

export const MediumImageWrapper = styled.div`
  height: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
`;

interface ImageThumbnailProps {
  $isSelected: boolean;
}

export const ImageThumbnail = styled.div<ImageThumbnailProps>`
  width: 100%;
  height: 93px;
  margin-bottom: 15px;
  border-bottom: ${({ $isSelected, theme }) =>
    `2px solid ${$isSelected ? theme.primary : "white"}`};
  transition: border-bottom 0.35s ease-in-out;
`;

export const OverlayImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  margin: 0 !important;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 !important;
`;

export type Position = { x: number; y: number };
type Size = { w: number; h: number };

interface ZoomAreaProps {
  $isVisible: boolean;
  position: Position;
  $parentSize: { w: number; h: number };
  $hoverSize: Size;
  $hoverOffset: Position;
}

export const ZoomArea = styled.div.attrs<ZoomAreaProps>((props) => {
  const { $isVisible, position, $parentSize, $hoverSize, $hoverOffset } = props;

  const left = $hoverOffset.x + position.x * $parentSize.w;
  const top = $hoverOffset.y + position.y * $parentSize.h;

  return {
    style: {
      visibility: $isVisible ? "visible" : "hidden",
      opacity: $isVisible ? 1 : 0,
      width: `${$hoverSize.w}px`,
      height: `${$hoverSize.h}px`,
      transform: `translate(${left}px, ${top}px`,
    },
  };
})`
  position: absolute;
  user-select: none;
  pointer-events: none;
  transition:
    visibility 0s,
    opacity 0.35s linear;
  top: 0;
  left: 0;

  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFUlEQVQYV2O0a/r+/1AdJyMjAxQAADkFBAMa8eBjAAAAAElFTkSuQmCC)
    repeat;
`;

export const NoImage = styled.img`
  height: 100%;
`;
