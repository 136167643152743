import React, { FC, memo } from "react";
import Icon from "components/Icon/Icon";
import Tooltip from "components/Tooltip/Tooltip";
import { IconType } from "enums/Icon";
import { fromJS, List, Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getAddonsCost } from "../../../../components/TotalCostHint/totalCostHelper";
import useFetchOnceEffect from "../../../../hooks/useFetchOnceEffect";
import { removeCartParcels } from "../../../../reduxFolder/reducers/cart";
import { removeItemFromConsolidation } from "../../../../reduxFolder/reducers/outgoing";
import {
  closeRightPanel,
  openRightPanel,
} from "../../../../reduxFolder/reducers/rightPanel";
import { getRequestTypes } from "../../../../reduxFolder/reducers/serviceRequests";
import {
  getAddonsMethods,
  getConsolidationAddonsMethods,
} from "../../../../reduxFolder/reducers/services";
import { removeConsolidationFromShipment } from "../../../../reduxFolder/reducers/storage";
import { cartSelectCartId } from "../../../../reduxFolder/selectors/cartSelectors";
import { requestsSelectServiceTypes } from "../../../../reduxFolder/selectors/serviceRequestsSelectors";
import {
  servicesSelectAddons,
  servicesSelectConsolidationAddons,
} from "../../../../reduxFolder/selectors/servicesSelectors";
import {
  isTransferringState,
  ITEM_TRANSFERRING_STATE,
} from "../../../../utils/common";
import {
  getActiveRequestForType,
  getActiveShipmentRequests,
} from "../ServiceRequests/requestHelper";
import { CustomRasterIcon } from "../ServiceRequests/ServiceRequest.styles";
import {
  ArrowIcon,
  HelpIcon,
  ParcelButton,
  RemoveButton,
  ReturnButton,
  ReturnSection,
  ReturnTooltip,
  ServicesSection,
  Status,
} from "./ServiceButtons.styles";

interface ServicesButtonsProps {
  shipment: any;
  openPackOptions: () => void;
  openServicePanel: (panel: string, isInventoryItemVariant?: boolean) => void;
  openInventory?: () => void;
  isCartVariant: boolean;
  hasInventoryItems?: boolean;
  originalItem?: any;
  isInventoryItemVariant?: boolean;
  isItemInCart?: boolean;
  addons?: any;
  consolidationAddons: any;
  cartId: string | number;
  closeRightPanel: () => void;
  getAddonsMethods: () => Promise<any>;
  getConsolidationAddonsMethods: () => Promise<any>;
  removeConsolidationFromShipment: (
    cartId: string | number,
    itemId?: string | number,
    isCartVariant?: boolean,
  ) => Promise<any>;
  removeCartParcels: (cartId: string | number) => void;
  removeItemFromConsolidation: (
    consolidationId: string | number,
    itemId?: string | number,
    isCartVariant?: boolean,
  ) => Promise<any>;
  serviceRequestsTypes: List<any>;
}

const ServicesButtons: FC<ServicesButtonsProps> = React.memo(
  ({
    openServicePanel,
    getAddonsMethods,
    getConsolidationAddonsMethods,
    consolidationAddons,
    openPackOptions,
    openInventory,
    addons = List(),
    shipment,
    removeItemFromConsolidation,
    removeCartParcels,
    isCartVariant,
    closeRightPanel,
    hasInventoryItems,
    isInventoryItemVariant,
    isItemInCart,
    originalItem,
    cartId,
    removeConsolidationFromShipment,
    serviceRequestsTypes,
  }) => {
    const { t } = useTranslation("common");
    useFetchOnceEffect(!addons.size, getAddonsMethods);
    useFetchOnceEffect(
      !consolidationAddons.size,
      getConsolidationAddonsMethods,
    );
    const addonsCost = getAddonsCost(fromJS([shipment]), addons);

    // Service request types
    const shipmentServiceRequests = shipment.get("service_requests");
    const activeShipmentRequests = getActiveShipmentRequests(
      serviceRequestsTypes,
      shipmentServiceRequests,
    );

    const description = shipment.get("description");
    const id = shipment.get("id");
    const consolidationId = shipment.getIn(["consolidation", "id"]);
    const hasInventory =
      hasInventoryItems ||
      (shipment.get("items") && shipment.get("items").size > 1);
    const consolidationState = shipment.getIn(["consolidation", "state"]);
    const isRemovableFromConsolidation =
      consolidationState === "pending" || consolidationState === "requesting";
    const requestInventoryShipmentStatus = shipment
      .get("unpacking_task", Map())
      .get("state");

    const isClientTransferringItem = isTransferringState(
      originalItem.get("state"),
    );
    const isItemTransferringVariant =
      originalItem.get("state") === ITEM_TRANSFERRING_STATE;
    const requestTransferButtonTitle = isClientTransferringItem
      ? t("serviceRequests.requestTransferShipment.transferCompleteCode")
      : t("serviceRequests.requestTransferShipment.title");

    const handleRemoveAllFromConsolidation = async () => {
      const consolidationItems = shipment.getIn(["consolidation", "items"], []);
      const itemsToRemove = consolidationItems.filter(
        (item: any) => item.get("shipment_id") === id,
      );

      const ajaxRequests = [];
      for (const item of itemsToRemove) {
        const itemId = item.get("id");
        ajaxRequests.push(
          removeItemFromConsolidation(
            consolidationId,
            itemId,
            isCartVariant,
          ).then(() => {
            removeCartParcels(itemId);
          }),
        );
      }

      await Promise.all(ajaxRequests);
      if (isCartVariant) {
        removeConsolidationFromShipment(id);
      }
      closeRightPanel();
    };

    const handleRemoveFromConsolidation = () => {
      const itemId = originalItem.get("id");

      removeItemFromConsolidation(cartId, itemId, isCartVariant).then(() => {
        removeCartParcels(itemId);
        closeRightPanel();
      });
    };

    if (isClientTransferringItem) {
      return (
        <ServicesSection>
          <ParcelButton
            onClick={() =>
              openServicePanel(
                "requestTransferShipment",
                isItemTransferringVariant,
              )
            }
          >
            <div>
              <Icon type={IconType.PackOptions} />
              {requestTransferButtonTitle}
            </div>
            <ArrowIcon type={IconType.Arrow} />
          </ParcelButton>
        </ServicesSection>
      );
    }

    if (isInventoryItemVariant) {
      return (
        <div>
          <ServicesSection>
            <ParcelButton onClick={openPackOptions}>
              <div>
                <Icon type={IconType.PackOptions} />
                {t("serviceRequests.packOptions.title")}
              </div>
              {!!addonsCost && <Status>$ {addonsCost}</Status>}
              <ArrowIcon type={IconType.Arrow} />
            </ParcelButton>
          </ServicesSection>
          <ServicesSection>
            <ParcelButton
              onClick={() => openServicePanel("requestTransferShipment", true)}
            >
              <div>
                <Icon type={IconType.PackOptions} />
                {t("serviceRequests.requestTransferShipment.title")}
              </div>
              <ArrowIcon type={IconType.Arrow} />
            </ParcelButton>
          </ServicesSection>
          {isItemInCart && (
            <ServicesSection>
              <ParcelButton onClick={handleRemoveFromConsolidation}>
                <RemoveButton>
                  <Icon type={IconType.Close} />
                  {t("serviceRequests.removeFromConsolidation.actionText")}
                </RemoveButton>
              </ParcelButton>
            </ServicesSection>
          )}
        </div>
      );
    }

    return (
      <div>
        {serviceRequestsTypes.map((req) => {
          const activeRequest = getActiveRequestForType(
            serviceRequestsTypes,
            activeShipmentRequests,
            req.get("type"),
          );

          return (
            <ServicesSection key={req.get("type")}>
              <ParcelButton onClick={() => openServicePanel(req.get("type"))}>
                <div>
                  {!!req.get("icon") && (
                    <CustomRasterIcon icon={req.get("icon")} />
                  )}
                  {req.get("title")}
                </div>
                {activeRequest && activeRequest.get("hasRequest") && (
                  <Status>
                    {t(`serviceRequests.states.${activeRequest.get("state")}`)}
                  </Status>
                )}
                <ArrowIcon type={IconType.Arrow} />
              </ParcelButton>
            </ServicesSection>
          );
        })}
        {!hasInventory && (
          <ServicesSection>
            <ParcelButton
              onClick={() => openServicePanel("requestInventoryShipment")}
            >
              <div>
                <Icon type={IconType.PackOptions} />
                {t("serviceRequests.requestInventoryShipment.title")}
              </div>
              {requestInventoryShipmentStatus === "requested" && (
                <Status>
                  {t(`serviceRequests.states.pending_translation`)}
                </Status>
              )}
              <ArrowIcon type={IconType.Arrow} />
            </ParcelButton>
          </ServicesSection>
        )}
        <ServicesSection>
          <ParcelButton onClick={openPackOptions}>
            <div>
              <Icon type={IconType.PackOptions} />
              {t("serviceRequests.packOptions.title")}
            </div>
            {!!addonsCost && <Status>$ {addonsCost}</Status>}
            <ArrowIcon type={IconType.Arrow} />
          </ParcelButton>
        </ServicesSection>
        {hasInventory && (
          <ServicesSection>
            <ParcelButton onClick={openInventory}>
              <div>
                <Icon type={IconType.PackOptions} />
                {t("serviceRequests.inventory.title")}
              </div>
              <ArrowIcon type={IconType.Arrow} />
            </ParcelButton>
          </ServicesSection>
        )}
        <ServicesSection>
          <ParcelButton
            onClick={() => openServicePanel("requestTransferShipment")}
          >
            <div>
              <Icon type={IconType.PackOptions} />
              {t("serviceRequests.requestTransferShipment.title")}
            </div>
            <ArrowIcon type={IconType.Arrow} />
          </ParcelButton>
        </ServicesSection>
        {isRemovableFromConsolidation && (
          <ServicesSection>
            <ParcelButton onClick={handleRemoveAllFromConsolidation}>
              <RemoveButton>
                <Icon type={IconType.Close} />
                {t("serviceRequests.removeFromConsolidation.actionText")}
              </RemoveButton>
            </ParcelButton>
          </ServicesSection>
        )}
        <ReturnSection>
          <ReturnButton>
            <div>
              <Icon type={IconType.Return} />
              {t("serviceRequests.returnPackage.title")}
            </div>
          </ReturnButton>
          <Tooltip
            body={
              <ReturnTooltip>
                {t("serviceRequests.returnPackage.writeUsFirstPart")}
                <a
                  href={`mailto:vsklad-returns@sklad.com?subject=Return package number ${description} with id ${id} to the store.`}
                >
                  vsklad-returns@sklad.com
                </a>
                {t("serviceRequests.returnPackage.writeUsSecondPart")}
              </ReturnTooltip>
            }
          >
            <HelpIcon type={IconType.Help} />
          </Tooltip>
        </ReturnSection>
      </div>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    serviceRequestsTypes: requestsSelectServiceTypes,
    addons: servicesSelectAddons,
    consolidationAddons: servicesSelectConsolidationAddons,
    cartId: cartSelectCartId,
  }),
  {
    getRequestTypes,
    getAddonsMethods,
    getConsolidationAddonsMethods,
    openRightPanel,
    closeRightPanel,
    removeItemFromConsolidation,
    removeCartParcels,
    removeConsolidationFromShipment,
  },
);

export default withConnect(ServicesButtons);
