import * as Sentry from '@sentry/react';
import axios from 'axios';
import { fromJS } from 'immutable';
import { redirect } from 'react-router-dom';
import { getAuthData, setAuthData } from './authData';

const getAxiosParams = () => {
  const { origin } = window.location;
  // development
  if (origin.includes('localhost'))
    return {
      baseURL: 'https://www.packed.ltd/client_api',
    };

  // staging
  if (
    origin.includes('192.168') ||
    origin.includes('staging') ||
    origin.includes('beta-app') ||
    origin.includes('brocoders.sklad')
  )
    return {
      baseURL: 'https://eks.packed.ltd/client_api',
      headers: {
        Authorization: 'Basic c2tsYWQ6b3p6a2xs',
      },
    };
  // production
  return {
    baseURL: 'https://eks.tapegun.me/client_api',
    headers: {
      Authorization: 'Basic c2tsYWQ6T29wZXF6ejEK',
    },
  };
};

const axiosInstance = axios.create(getAxiosParams());

axiosInstance.interceptors.request.use((request) => {
  Object.assign(request.headers, getAuthData());
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const { request, headers, data } = response;

    if (request.responseURL.includes('/auth') && headers['access-token']) {
      setAuthData(headers);
    }

    return fromJS({ headers, data });
  },
  ({ response = {}, message }) => {
    const { headers, data, status } = response;
    const { pathname } = window.location;

    Sentry.captureException(error);

    if (!response) {
      return Promise.reject({
        message: `Network error: ${message || 'No response from server'}`,
        status: null,
        data: null,
      });
    }

    if (
      status === 401 &&
      !(pathname.includes('signin') || pathname.includes('signup'))
    )
      redirect(pathname, {
        //state: { signInModal: true },
      });

    if (headers && headers['access-token']) setAuthData(headers);

    return Promise.reject({ data, message, status });
  },
);

export default axiosInstance;
