import React from 'react';
import { fromJS, List, Map } from 'immutable';
import { combineActions, createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = fromJS({
  isLoading: false,
  types: null,
  transferRequests: {},
});

// const
export const GET_TYPES = 'serviceRequest/GET_TYPES';
export const GET_SHIPMENT_TYPE = 'serviceRequest/GET_SHIPMENT_TYPE';
export const CREATE_REQUEST = 'serviceRequest/CREATE_REQUEST';
export const CANCEL_REQUEST = 'serviceRequest/CANCEL_REQUEST';
export const CANCEL_REQUEST_INVENTORY_REQUEST =
  'serviceRequest/CANCEL_REQUEST_INVENTORY_REQUEST';
// TODO: FIXME:
export const requestNames = {
  'additional photos': 'additionalPhotos',
  custom: 'serviceByInstruction',
  requestinventoryshipment: 'requestInventoryShipment',
};
export const CREATE_REQUEST_INVENTORY_SHIPMENT =
  'serviceRequest/CREATE_REQUEST_INVENTORY_SHIPMENT';

// Transfer
export const CREATE_REQUEST_TRANSFER_SHIPMENT =
  'serviceRequest/CREATE_REQUEST_TRANSFER_SHIPMENT';
export const CREATE_REQUEST_TRANSFER_INVENTORY_ITEMS =
  'serviceRequest/CREATE_REQUEST_TRANSFER_INVENTORY_ITEMS';
export const COMPLETE_REQUEST_TRANSFER_SHIPMENTS =
  'serviceRequest/COMPLETE_REQUEST_TRANSFER_SHIPMENTS';
export const COMPLETE_REQUEST_TRANSFER_INVENTORY_ITEMS =
  'serviceRequest/COMPLETE_REQUEST_TRANSFER_INVENTORY_ITEMS';

// action creators
export const getRequestTypes = createAction(
  GET_TYPES,
  api.serviceRequests.getRequestTypes,
);

export const getShipmentRequestType = createAction(
  GET_SHIPMENT_TYPE,
  api.serviceRequests.getShipmentRequestType,
);

export const createServiceRequest = createAction(
  CREATE_REQUEST,
  api.serviceRequests.createServiceRequest,
);

export const cancelServiceRequest = createAction(
  CANCEL_REQUEST,
  api.serviceRequests.cancelServiceRequest,
);

export const cancelRequestInventoryServiceRequest = createAction(
  CANCEL_REQUEST_INVENTORY_REQUEST,
  api.serviceRequests.cancelRequestInventoryServiceRequest,
);

export const createRequestInventoryShipment = createAction(
  CREATE_REQUEST_INVENTORY_SHIPMENT,
  api.serviceRequests.createRequestInventoryShipment,
);

export const createRequestTransferShipments = createAction(
  CREATE_REQUEST_TRANSFER_SHIPMENT,
  ({ ids, partnerTransferId }) => ({
    promise: api.serviceRequests.createRequestTransferShipments({
      ids,
      partnerTransferId,
    }),
    data: { ids, partnerTransferId },
  }),
  // This will be passed as meta to reducer (including SUCCESS and ERROR actions)
  ({ ids, partnerTransferId }) => ({
    ids,
    partnerTransferId,
  }),
);

export const createRequestTransferInventoryItems = createAction(
  CREATE_REQUEST_TRANSFER_INVENTORY_ITEMS,
  ({ ids, partnerTransferId, sku }) => ({
    promise: api.serviceRequests.createRequestTransferInventoryItem({
      sku,
      partnerTransferId,
    }),
    data: { sku, partnerTransferId },
  }),
  // This will be passed as meta to reducer (including SUCCESS and ERROR actions)
  ({ ids, sku, partnerTransferId }) => ({
    sku,
    ids,
    partnerTransferId,
  }),
);

export const completeRequestTransferShipments = createAction(
  COMPLETE_REQUEST_TRANSFER_SHIPMENTS,
  ({ codes }) => ({
    promise: api.serviceRequests.completeRequestTransferShipments({
      codes,
    }),
    data: { codes },
  }),
);

export const completeRequestTransferInventoryItems = createAction(
  COMPLETE_REQUEST_TRANSFER_INVENTORY_ITEMS,
  ({ codes }) => ({
    promise: api.serviceRequests.completeRequestTransferInventoryItems({
      codes,
    }),
    data: { codes },
  }),
);

// helpers
const normalizeServiceRequestItem = (group) => {
  return group.map((item) =>
    item.merge({
      title: item.get('type'),
      type: item.get('type').toLowerCase(),
    }),
  );
};

const normalizeServiceRequests = (requests) => {
  let retVal = new List();

  // Request type which have "group" defined
  const groupRequests = requests
    .filter((x) => !!x.get('group'))
    .groupBy((x) => x.get('group'));

  for (const [key, group] of groupRequests) {
    const normalizedGroup = normalizeServiceRequestItem(group);
    retVal = retVal.push(
      new Map({
        complexType: 'group',
        type: key.toLowerCase(),
        title: key,
        description: normalizedGroup
          .find((x) => x.get('description'))
          ?.get('description'),
        hasComment: normalizedGroup.some((x) => x.get('has_comment')),
        requestKeys: new Map({
          ...normalizedGroup.reduce(
            (a, v) => ({
              ...a,
              [v.get('type')]: v.merge({
                type: v.get('type'),
              }),
            }),
            {},
          ),
        }),
        icon:
          normalizedGroup
            .find((x) => x.get('icon')?.get('url'))
            ?.get('icon')
            .get('url') ?? null,
        requestsList: normalizedGroup,
      }),
    );
  }

  // Request type which have "collection" defined
  const collectionRequests = requests
    .filter((x) => !!x.get('collection'))
    .groupBy((x) => x.get('collection'));

  for (const [key, collection] of collectionRequests) {
    const normalizedCollection = normalizeServiceRequestItem(collection);
    retVal = retVal.push(
      new Map({
        complexType: 'collection',
        type: key.toLowerCase(),
        title: key,
        description: normalizedCollection
          .find((x) => x.get('description'))
          ?.get('description'),
        hasComment: normalizedCollection.some((x) => x.get('has_comment')),
        requestKeys: new Map({
          ...normalizedCollection.reduce(
            (a, v) => ({
              ...a,
              [v.get('type')]: v.merge({
                type: v.get('type'),
              }),
            }),
            {},
          ),
        }),
        icon:
          normalizedCollection
            .find((x) => x.get('icon')?.get('url'))
            ?.get('icon')
            .get('url') ?? null,
        requestsList: normalizedCollection,
      }),
    );
  }

  // Remaining request type (which are not "group" or "collection"
  const remainingRequests = requests.filter(
    (x) => !x.get('group') && !x.get('collection'),
  );

  for (const request of remainingRequests) {
    const reqProps = request.toJS();
    delete reqProps['has_comment'];
    delete reqProps['group'];
    delete reqProps['collection'];
    retVal = retVal.push(
      new Map({
        ...reqProps,
        type: request.get('type').toLowerCase(),
        title: request.get('type'),
        hasComment: request.get('has_comment'),
        icon: request.get('icon')?.get('url') ?? null,
      }),
    );
  }

  return retVal;
};

const serviceRequestsReducer = handleActions(
  {
    [`${GET_TYPES}_REQUEST`]: (state) => state.set('isLoading', true),

    [`${GET_TYPES}_SUCCESS`]: (state, { payload }) =>
      state
        .set('types', normalizeServiceRequests(payload.get('data')))
        .set('isLoading', true),

    [`${GET_SHIPMENT_TYPE}_SUCCESS`]: (state, { payload }) =>
      state
        .set('types', normalizeServiceRequests(payload.get('data')))
        .set('isLoading', true),

    [combineActions(
      `${CREATE_REQUEST_TRANSFER_SHIPMENT}_SUCCESS`,
      `${CREATE_REQUEST_TRANSFER_INVENTORY_ITEMS}_SUCCESS`,
    )]: (state, { payload, meta }) => {
      const ids = meta.ids;
      const transferCode = payload.get('data').get('code');

      return state.set('transferRequests', {
        ...state.transferRequests,
        ...ids.reduce(
          (a, id) => ({
            ...a,
            [id]: {
              id,
              status: 'requested',
              transferCode,
            },
          }),
          {},
        ),
      });
    },

    [`${GET_TYPES}_ERROR`]: (state) => state.set('isLoading', false),
  },
  initialState,
);

export default serviceRequestsReducer;
