import React, { FC, useState } from "react";
import { IconType } from "enums/Icon";
import { List } from "immutable";
import { useTranslation } from "react-i18next";
import { RasterIcon } from "../../../../components/Icon/Icon";
import ImageViewer2 from "../../../../components/ImageViewer2/ImageViewer2";
import { Message, MessageIcon } from "../../../../styles/serviceRequestStyles";
import { cancelableStates } from "../ServiceRequests/requestHelper";
import {
  IconWrapper,
  MessageBlock,
  ProcessIcon,
  UserComment,
  VideoPlayer,
  WarehouseComment,
  WarehouseCommentWrapper,
} from "./RequestInfo.styles";

interface RequestInfoProps {
  request: any;
}

const RequestInfo: FC<RequestInfoProps> = ({ request }) => {
  const [isImageOpened, setImageOpened] = useState(false);
  const { t } = useTranslation("common");
  const requestType = request.get("type");
  const icon = request.get("icon");

  const userComment = request.get("user_comment");
  const video = request.get("vimeo_id");
  const pictures = request.get("pictures", List()).toJS();
  const warehouseComment =
    request.get("additional_photos_warehouse_comment") ||
    request.get("power_on_test_warehouse_comment") ||
    request.get("custom_warehouse_comment");

  const renderPendingTranslation = () => (
    <MessageBlock>
      {icon && (
        <IconWrapper>
          <ProcessIcon type={IconType.Process} />
          <RasterIcon icon={icon} />
        </IconWrapper>
      )}
      <Message>{t(`serviceRequests.unprocessed`)}</Message>
      {requestType !== "requestInventoryShipment" && (
        <Message>{t("serviceRequests.processTime")}</Message>
      )}
    </MessageBlock>
  );
  const renderRequested = () => (
    <>
      {warehouseComment && !isImageOpened && (
        <WarehouseCommentWrapper>
          <b>{t("parcels.warehouseComment")}:</b>
          <WarehouseComment>{warehouseComment}</WarehouseComment>
        </WarehouseCommentWrapper>
      )}
      {userComment && (
        <UserComment>
          <b>{t("common.comment")}: </b>
          {userComment}
        </UserComment>
      )}
      {video && !isImageOpened && (
        <VideoPlayer url={`https://vimeo.com/${video}`} />
      )}
      {!!pictures.length && <ImageViewer2 images={pictures} />}
    </>
  );

  return (
    <>
      {cancelableStates.includes(request.get("state")) &&
        renderPendingTranslation()}
      {request.get("state") === "requested" && renderRequested()}
    </>
  );
};

export default React.memo(RequestInfo);
