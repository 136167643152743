import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { OpenRightPanel } from "types/common/rightPanel";
import { PackOptionsFormValues } from "types/StorageForms/PackOptions";
import {
  toastResponseError,
  toastResponseSuccess,
} from "utils/responseMessageHelper";

export const packOptionsFormik = (
  t: TFunction,
  openRightPanel: OpenRightPanel,
  detailedShipment: any,
  outgoingDetailedShipment: any,
  panelData: any,
  updateAddons: (
    id: string,
    data: { addon_ids: any },
    inventoryId: string | null,
  ) => Promise<any>,
) => {
  const getInitialValues = () => {
    const id = panelData.get("id");
    const shipmentAddons =
      detailedShipment.getIn([panelData.get("id"), "addons"]) ||
      outgoingDetailedShipment.getIn([panelData.get("id"), "addons"]);

    return {
      activeItem: { value: id },
      [id]: shipmentAddons,
    };
  };

  const initialValues = getInitialValues();

  return {
    enableReinitialize: true,
    initialValues,
    onSubmit: (
      values: PackOptionsFormValues,
      { setSubmitting }: FormikHelpers<PackOptionsFormValues>,
    ) => {
      const id = panelData.get("id");
      const returnScreen = panelData.get("returnScreen");
      const useInventoryAddons = panelData.get("useInventoryAddons");

      if (useInventoryAddons) {
        const inventoryId = panelData.get("originalItem").get("id");

        return updateAddons(
          id,
          {
            addon_ids: values[id],
          },
          inventoryId,
        )
          .then(() => {
            toastResponseSuccess(
              t("parcels.packOptionsSaved", {
                number: panelData.get("description"),
              }),
            );
            openRightPanel(returnScreen, panelData);
          })
          .catch((error: Error) => toastResponseError(error))
          .finally(() => setSubmitting(false));
      }

      return updateAddons(
        id,
        {
          addon_ids: values[id],
        },
        null,
      )
        .then(() => {
          toastResponseSuccess(
            t("parcels.packOptionsSaved", {
              number: panelData.get("description"),
            }),
          );
          openRightPanel(returnScreen, panelData);
        })
        .catch((error: Error) => toastResponseError(error))
        .finally(() => setSubmitting(false));
    },
  };
};
