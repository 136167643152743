import React, { FC, useState } from "react";
import { Map } from "immutable";
import { connect } from "react-redux";
import { ItemsReceivedItemDto } from "types/api/items";
import { OpenRightPanel } from "types/common/rightPanel";
import { openRightPanel } from "../../../reduxFolder/reducers/rightPanel";
import {
  getShipment,
  updateComment,
  updateItemComment,
} from "../../../reduxFolder/reducers/storage";
import { InventoryListWrapper } from "../styles/Inventory.style";
import InventoryItemRow from "./InventoryItemRow/InventoryItemRow";

interface InventoryListProps {
  openRightPanel: OpenRightPanel;
  getShipment: (id: number) => Promise<void>;
  updateItemComment: (id: number, data: ItemsReceivedItemDto) => Promise<void>;
  units: string;
  items: any;
  shipment: any;
  selectedItems: any;
  disabled: boolean;
  getIsItemInCart: (itemId: string) => void;
  handleSelectItems: (items: any) => void;
  isInventoryItemsSearchVariant?: boolean;
}

const InventoryList: FC<InventoryListProps> = React.memo(
  ({
    updateItemComment,
    getShipment,
    openRightPanel,
    items,
    selectedItems,
    disabled,
    getIsItemInCart,
    handleSelectItems,
    shipment,
    isInventoryItemsSearchVariant,
    units,
  }) => {
    const [selectedInventoryItem, setSelectedInventoryItem] = useState(null);
    // Don't display Q items in inventory
    const filteredItems = items.filter(
      (item: any) => !item.get("sku").startsWith("Q"),
    );

    const handleGetShipment = (id: number) => {
      if (isInventoryItemsSearchVariant) {
        getShipment(id);
      }

      return Promise.resolve();
    };

    const handleFocusInventoryItemInPanel = (item: any) => {
      setSelectedInventoryItem(item.get("id"));
      openRightPanel(
        "STORAGE_ITEM",
        Map({
          id: isInventoryItemsSearchVariant
            ? item.get("shipment_id")
            : shipment.get("id"),
          getShipment: handleGetShipment,
          updateItemComment,
          originalItem: item,
          isItemInCart: getIsItemInCart(item.get("id")),
          isCartVariant: true,
        }),
      );
    };

    return (
      <InventoryListWrapper>
        {filteredItems.map((inventoryItem: any) => (
          <InventoryItemRow
            units={units}
            key={inventoryItem.get("id")}
            inventoryItem={inventoryItem}
            selectedInventoryItem={selectedInventoryItem}
            isItemInCart={getIsItemInCart(inventoryItem.get("id"))}
            isSelected={
              selectedItems[inventoryItem.get("id")] &&
              selectedItems[inventoryItem.get("id")].isSelectedState
            }
            onChange={({
              isSelected,
              selectedQuantity,
            }: {
              isSelected: boolean;
              selectedQuantity: any;
            }) =>
              handleSelectItems({
                [inventoryItem.get("id")]: {
                  id: inventoryItem.get("id"),
                  isSelectedState: isSelected,
                  selectedQuantity,
                },
              })
            }
            disabledParent={disabled}
            onFocusInventoryItemInPanel={handleFocusInventoryItemInPanel}
          />
        ))}
      </InventoryListWrapper>
    );
  },
);

const withConnect = connect(null, {
  openRightPanel,
  getShipment,
  updateComment,
  updateItemComment,
});

export default withConnect(InventoryList);
